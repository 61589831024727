.rc-menu-submenu-popup,
.display-app {
    font-size: 14px;
    font-family: Arial, sans-serif;
}

.rc-menu.display-app {
    margin-top: 0;
}

.rc-menu-submenu-popup .rc-menu-item,
.rc-menu-submenu-popup .rc-menu-submenu .rc-menu-submenu-title,
.display-app > .rc-menu-item,
.display-app > .rc-menu-submenu > .rc-menu-submenu-title {

    padding: 6px 12px;
}
