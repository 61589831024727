
.app-playground {
    /*background-color:rgba(255,0,0,0.5);*/
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    pointer-events:none;

}
.deck-gl-window,
.map-window {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}


.rnd-window {
    pointer-events: auto;
    background-color: white;
    border: 1px solid darkgray;
    /*border-radius: 1px;*/
    /*padding: 10px;*/
    /*max-height: 100%;*/
}

/* width */
.rnd-window::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.rnd-window::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.rnd-window::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #ccc;
}

/* Handle on hover */
.rnd-window::-webkit-scrollbar-thumb:hover {
    background: #888;
}

.rnd-toolbar {
    background-color: white;
    border: 1px solid darkgray;
    border-radius: 4px;
    padding: 1px;
}

.rnd-window .rnd-dragbar {
    background-color: #9198b9;
    border: 1px solid darkgray;
    color: white;
    font-size: 11px;
    border-radius: 1px;
    height:16px;
    width: 100%;
    cursor: move;
}

.attribution {
    letter-spacing: 0.5px;
    font-weight: 100;
    color: white;
    text-shadow:
            -1px -1px 0 #666,
            1px -1px 0 #666,
            -1px 1px 0 #666,
            1px 1px 0 #666;
    font-size: 10px;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.attribution a {
    color: white;
    text-decoration: none;

}

.attribution a:hover
{
    color: white;
    text-decoration:none;
    cursor:pointer;
}


