#help-content {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    padding: 2% 10%;
}
#help-image {
    width: 100%;
    box-sizing: border-box;
    margin-top: 1rem;
}

#rodo-content {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
