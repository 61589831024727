/*------------ANIMATION------------*/

.sidemenu-enter {
    opacity: 0.01;
    transform: scale(0.1) translateY(50%);
}
.sidemenu-enter-active {
    opacity: 1;
    transform: scale(1) translateY(0%);
    transition: all 300ms ease-out;
    transition-delay: 300ms;
}
.menucard-enter {
    opacity: 0.01;
}
.menucard-enter-active {
    opacity: 1;
    transition: all 300ms ease-out;
    transition-delay: 300ms;
}
.menucard-exit-active {
    opacity: 0.01;
    transition: all 300ms ease-out;
    transition-delay: 0ms;
}

/*------------STATIC------------*/
/* Tree component related */
svg[style*="opacity: 0.3"][class=toggle] {
    display: none;
}
.treeview > div[style*="1px dashed rgba(255, 255, 255, 0.4)"] {
    border-left: 1px dashed rgba(0, 0, 0, 0.2) !important;
}

